import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/kalpesh_shah.JPG";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={6} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> Kalpesh Shah</strong>
              </h1>

              <p className="heading-description blockquote">
                I’m an Financial advisor offering personalized solutions to
                secure your financial future. Let’s create a plan that protects
                your assets and grows your wealth.
              </p>

              <div className="heading-type">
                {/* <TypeWriter /> */}
                <h4 className="main-name">
                  <strong>Doctor's Save Life We Save Family</strong>
                </h4>
              </div>
            </Col>

            <Col md={5}>
              <img src={myImg} className="profile-pic" alt="avatar" />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
