import LIFEINSURANCE from "./Assets/icons/lifeInsurance.png";
import HEALTHINSURANCE from "./Assets/icons/healthInsurance.png";
import HOMEINSURANCE from "./Assets/icons/homeInsurance.png";
import VEHICALINSURANCE from "./Assets/icons/vehicalInsurance.png";
import BUSINESSINSURANCE from "./Assets/icons/businessInsurance.png";
import PROPERTYINSURANCE from "./Assets/icons/propertyInsurance.png";

export const PROJECTS = [
  {
    image: LIFEINSURANCE,
    name: "Life Insurance",
    description:
      "Life insurance is a crucial step in ensuring your family's financial stability when you're no longer there to provide for them. It offers a tax-free payout that can help cover significant expenses such as mortgages, medical bills, and education costs. Beyond immediate financial relief, life insurance provides long-term peace of mind, allowing your loved ones to maintain their standard of living and achieve future goals. Whether it's supporting daily expenses or safeguarding your children's future, a life insurance policy offers a reliable financial cushion during challenging times, ensuring your family's well-being is always protected.",
  },
  {
    image: HEALTHINSURANCE,
    name: "Health Insurance",
    description: `Health insurance provides essential protection against high medical costs, ensuring you receive the care you need without the financial burden. It covers a wide range of medical services, including hospital stays, surgeries, prescription medications, and preventive care. By having health insurance, you gain access to a network of healthcare providers, receive timely medical attention, and safeguard your savings from unexpected healthcare expenses. Whether it's routine checkups or emergency treatments, health insurance offers peace of mind, knowing you are covered when it matters most.`,
  },
  {
    image: BUSINESSINSURANCE,
    name: "Mutual Fund",
    description:
      "Mutual funds are a smart investment option that pools money from multiple investors to invest in a diversified portfolio of stocks, bonds, or other securities. Managed by professional fund managers, mutual funds offer a convenient way for individuals to access a wide range of investments without needing extensive market knowledge. With options tailored to various risk levels and financial goals, mutual funds provide growth opportunities while spreading risk across different assets. Whether you're saving for retirement, education, or other long-term goals, mutual funds offer a flexible and accessible path to building wealth.",
  },
];

export const SKILLS = [
  { name: "ReactJs", initialRating: 4 },
  { name: "React-Native", initialRating: 4 },
  { name: "JavaScript", initialRating: 4 },
  { name: "Redux", initialRating: 4 },
  { name: "Shopify/Reactjs", initialRating: 4 },
  { name: "Nodejs", initialRating: 3 },
  { name: "Koajs", initialRating: 3 },
  { name: "Html", initialRating: 4 },
  { name: "CSS", initialRating: 4 },
  { name: "Unit-Testing(Jest)", initialRating: 3 },
  { name: "TypeScript", initialRating: 4 },
  { name: "Graphql", initialRating: 4 },
  { name: "Apollo", initialRating: 4 },
  { name: "AntD | ReactStrap | Material UI", initialRating: 4 },
  { name: "Shopify Polaris", initialRating: 4 },
  { name: "Git", initialRating: 4 },
];

export const TOOLS = [
  "Visual Studio Code",
  "Git",
  "Chrome DevTools",
  "Npm (Node Package Manager)",
  "Atom",
  "Sublime Text",
  "Linux",
  "Heroku",
  "Postman",
];
