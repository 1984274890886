import React from "react";
import A1 from "../../Assets/a-1.jpeg";
import A2 from "../../Assets/a-2.jpeg";
import A3 from "../../Assets/a-3.jpeg";
import family from "../../Assets/family.JPG";
import { Container } from "react-bootstrap";
const achievements = [
  {
    title: "Top Sales Performer",
    description: "Awarded for achieving the highest sales in Q3 2024.",
    date: "September 2024",
    image: A1, // Use your actual image paths
  },
  {
    title: "Best Innovation Award",
    description: "Recognized for introducing AI-powered sales assistant.",
    date: "August 2024",
    image: A2,
  },
  {
    title: "Team Collaboration Award",
    description: "For exceptional team collaboration in launching GDX Sports.",
    date: "July 2024",
    image: A3,
  },
];

const Achievements = () => {
  return (
    <Container className="achievements-section">
      <div className="mb-5">
        <h1 className="project-heading">
          <strong className="purple">Legacy</strong>
        </h1>
        <p>
          We are thrilled to announce that RUSHI KALPESHKUMAR SHAH is joining
          our family business! This marks a significant milestone in our
          company's history and ensures the continuation of our legacy. He
          brings fresh ideas, new energy, and a passion for innovation, which
          will help us continue to serve our clients with excellence. We are
          proud to see our family's next generation take the reins and lead us
          into a bright future.
        </p>
        <img src={family} alt="Family" className="family-image-style" />
      </div>
      <h1 className="project-heading">
        <strong className="purple">Achievements </strong>
      </h1>
      <div className="achievements-container">
        {achievements.map((achievement, index) => (
          <div className="achievement-card" key={index}>
            <img
              src={achievement.image}
              alt={achievement.title}
              className="achievement-image"
            />
            {/* <div className="achievement-content">
              <h2 className="achievement-title">{achievement.title}</h2>
              <p className="achievement-description">
                {achievement.description}
              </p>
              <p className="achievement-date">{achievement.date}</p>
            </div> */}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Achievements;
