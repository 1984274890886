import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            As a seasoned financial advisor with over 35 years of experience, I,
            Kalpesh Kanubhai Shah, have dedicated my career to helping
            individuals and families achieve their financial goals. With a
            proven track record of success and a passion for building long-term
            relationships, I have established myself as a trusted expert in the
            field. My expertise includes retirement planning, investment
            strategies, and wealth management. I am proud to have been
            recognized as a Top Agent at the India level and am committed to
            continuing my professional growth and education to best serve my
            clients.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
