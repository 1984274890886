import React, { useState, useEffect, useRef } from "react";
import Preloader from "./components/Preloader";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "./components/ScrollToTop";
import Main from "./components/Main";

function App() {
  const [load, upadateLoad] = useState(true);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const resumeRef = useRef(null);
  const achievementsRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      {load ? (
        <Preloader load={load} />
      ) : (
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar
            homeRef={homeRef}
            aboutRef={aboutRef}
            projectsRef={projectsRef}
            resumeRef={resumeRef}
            achievementsRef={achievementsRef}
          />
          <ScrollToTop />
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Main
                  homeRef={homeRef}
                  aboutRef={aboutRef}
                  projectsRef={projectsRef}
                  resumeRef={resumeRef}
                  achievementsRef={achievementsRef}
                />
              )}
            />
          </Switch>
          <Footer />
        </div>
      )}
    </Router>
  );
}

export default App;
