import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import developer from "../../Assets/li-plus-investment.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple">About Us</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col md={5} className="about-img">
            <img src={developer} alt="about" className="image-style" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
